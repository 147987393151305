import { buildCollection, buildProperty } from "firecms";
import { collectionVersionedPrefix } from "../data_version";

export const contractsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/contracts`,
    name: "Contracts",
    singularName: "Contract",
    group: "Main",
    description: "Contract Configurations",
    textSearchEnabled: false,
    icon: "RequestPage",

    properties: {
        name: {
            name: "Name",
            dataType: "string",
            validation: { required: true }
        },
        configuration: buildProperty({
            name: "Contract Configuration",
            dataType: "map",
            properties: {
                revenuePercent: {
                    name: "Revenue [%]",
                    description: "Revenue [%]",
                  validation: { required: true },
                  dataType: "number"
                }
              },
            expanded: true
        }),
        startDate: buildProperty({
            name: "Contract Start Date",
            dataType: "date",
            expanded: true,
            mode: "date"
        }),
        endDate: buildProperty({
            name: "Contract End Date",
            dataType: "date",
            expanded: true,
            mode: "date"
        }),
        modifiedAt: {
            name: "Modified At",
            dataType: "date",
            autoValue: "on_update",
            readOnly: true,
        },
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
    }
});
