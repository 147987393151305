import { buildCollection, buildProperty } from "firecms";
import { collectionVersionedPrefix } from "../data_version";

export const thirdPartyCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/third_party`,
    name: "3rd Party Config",
    singularName: "3rd Party Config",
    group: "Main",
    description: "3rd Party Config",
    textSearchEnabled: false,
    icon: "ArrowCircleDown",
    properties: {
      name: {
        name: "Name",
        dataType: "string",
        validation: { required: true },
    },
    transactions: buildProperty({
      name: "Transactions",
      dataType: "map",
      properties: {
        paypalApiKey: {
          name: "Paypal Api Key",
          dataType: "string",
          description: "Paypal Api Key",
        },
        },
      expanded: true,
  }),
  google: buildProperty({
    name: "Google",
    dataType: "map",
    properties: {
        googleFontsApiKey: {
          name: "Google Fonts API Key",
          dataType: "string",
          description: "Google Fonts API Key",
        },
        googleMapsApiKey: {
          name: "Google Maps API Key",
          dataType: "string",
          description: "Google Maps API Key",
        },
      },
    expanded: true,
    }),
  wordpress: buildProperty({
    name: "Wordpress",
    dataType: "map",
    properties: {
        wordpressApiKey: {
          name: "Wordpress API Key",
          dataType: "string",
          description: "Wordpress API Key",
        },
        wordpressUrl: {
          name: "Wordpress URL",
          dataType: "string",
          description: "Wordpress URL",
        },
      },
    expanded: true,
    }),
    webcams: buildProperty({
      name: "Webcams",
      dataType: "map",
      properties: {
          wordpressApiKey: {
            name: "Wordpress API Key",
            dataType: "string",
            description: "Wordpress API Key",
          },
          wordpressUrl: {
            name: "Wordpress URL",
            dataType: "string",
            description: "Wordpress URL",
          },
        },
      expanded: true,
    }),
    modifiedAt: {
      name: "Modified At",
      dataType: "date",
      autoValue: "on_update",
      readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    },
});
