import {
    continents as libContinents,
    countries as libCountries,
} from "countries-list";
import { EnumValues } from "firecms";

export const locales: EnumValues = {
    en: "English",
    es: "Spanish",
    de: "German",
    it: "Italian",
    fr: "French",
    cs: "Czech",
    sk: "Slovak"
};

export const currencies: EnumValues = [
    { id: "EUR", label: "Euros", color: "blueDark" },
    { id: "DOL", label: "Dollars", color: "greenLight" }
];

export const categories: EnumValues = {
    art_and_decoration: "Art and decoration",
    art_design_books: "Art and design books",
    babys: "Babies and kids",
    backpacks: "Backpacks and bags",
    bath: "Bath",
    bicycle: "Bicycle",
    books: "Books",
    cameras: "Cameras",
    clothing_man: "Clothing man",
    clothing_woman: "Clothing woman",
    coffee_and_tea: "Coffee and tea",
    cookbooks: "Cookbooks",
    delicatessen: "Delicatessen",
    desk_accessories: "Desk accessories",
    exercise_equipment: "Exercise equipment",
    furniture: "Furniture",
    gardening: "Gardening",
    headphones: "Headphones",
    home_accessories: "Home accessories",
    home_storage: "Home storage",
    kitchen: "Kitchen",
    lighting: "Lighting",
    music: "Music",
    outdoors: "Outdoors",
    personal_care: "Personal care",
    photography_books: "Photography books",
    serveware: "Serveware",
    smart_home: "Smart Home",
    sneakers: "Sneakers",
    speakers: "Speakers",
    sunglasses: "Sunglasses",
    toys_and_games: "Toys and games",
    watches: "Watches"
};

// countries

type CountryRecord = {
name: string;
code: string;
};

const countryComparison = (a: CountryRecord, b: CountryRecord) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
};

export const countries: EnumValues = Object.keys(libCountries)
    .map((k) => {
        return {
        code: k,
        name: libCountries[k as keyof typeof libCountries].name,
        continentCode: libCountries[k as keyof typeof libCountries].continent,
        };
    })
    .sort(countryComparison)
    .map((c) => {
        return {
        code: c.code,
        name: `${c.name} (${libContinents[c.continentCode as keyof typeof libContinents]})`,
        };
    })
    .map((country) => {
        return {
        id: country.code,
        label: country.name,
        };
    });
