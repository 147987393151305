import { buildCollection, buildProperty } from "firecms";
import { GenerateMediaKitActions } from "../collection_actions/GenerateMediaKit";
import { collectionVersionedPrefix } from "../data_version";
import { countries } from "./enums";

export const operatorsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/operators`,
    name: "Tour Operators",
    singularName: "Tour Operator",
    group: "Main",
    description: "Tour Operators",
    textSearchEnabled: false,
    icon: "Store",
    Actions: GenerateMediaKitActions,
    properties: {
        active: {
            name: "Active",
            dataType: "boolean",
        },
        name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
        },
        logoFirebaseUrl: buildProperty({
            name: "Logo",
            dataType: "string",
            storage: {
                storagePath: "images/operator_logos",
                acceptedFiles: ["image/*"],
                metadata: {
                    cacheControl: "max-age=1000000"
                }
            },
            validation: { required: true },
        }),
        logoFirebaseSignedUrl: {
            name: "Logo (signed)",
            dataType: "string",
            readOnly: true
          },
        countries: {
            name: "Countries",
            dataType: "array",
            of: {
                dataType: "string",
                enumValues: countries
            },
            validation: { required: true }
        },
        contact: buildProperty({
            name: "Contact",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                email: {
                    name: "Email",
                    description: "Email",
                    dataType: "string",
                    email: true,
                    validation: { required: true },
                },
                whatsapp: {
                    name: "Whatsapp",
                    description: "Whatsapp",
                    dataType: "string"
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string"
                }
            },
            expanded: true,
            validation: { required: true },
        }),
        officeAddress: buildProperty({
            name: "Office Address",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                email: {
                    name: "Email",
                    description: "Email",
                    dataType: "string",
                    email: true,
                    validation: { required: true },
                },
                whatsapp: {
                    name: "Whatsapp",
                    description: "Whatsapp",
                    dataType: "string",
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine1: {
                    name: "Street",
                    description: "Street",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine2: {
                    name: "P.O.Box, Floor, etc",
                    description: "P.O.Box, Floor, etc",
                    dataType: "string",
                },
                zip: {
                    name: "ZIP/Postal Code",
                    description: "ZIP/Postal Code",
                    dataType: "string",
                    validation: { required: true },
                },
                city: {
                    name: "Town/City",
                    description: "Town/City",
                    dataType: "string",
                    validation: { required: true },
                },
                country: {
                    name: "Country",
                    description: "Country",
                    dataType: "string",
                    enumValues: countries,
                    validation: { required: true },
                },
                
            },
            expanded: true,
        }),
        taxAddress: buildProperty({
            name: "Tax Information",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string",
                    validation: { required: true },
                },
                businessId: {
                    name: "Business ID",
                    description: "Business ID",
                    dataType: "string",
                    validation: { required: true },
                },
                vatId: {
                    name: "VAT ID",
                    description: "VAT ID",
                    dataType: "string",
                },
                addressLine1: {
                    name: "Street",
                    description: "Street",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine2: {
                    name: "P.O.Box, Floor, etc",
                    description: "P.O.Box, Floor, etc",
                    dataType: "string",
                },
                zip: {
                    name: "ZIP/Postal Code",
                    description: "ZIP/Postal Code",
                    dataType: "string",
                    validation: { required: true },
                },
                city: {
                    name: "Town/City",
                    description: "Town/City",
                    dataType: "string",
                    validation: { required: true },
                },
                country: {
                    name: "Country",
                    description: "Country",
                    dataType: "string",
                    enumValues: countries,
                    validation: { required: true },
                }
            },
            expanded: true,
        }),
        duns: {
            name: "D-U-N-S",
            description: "D-U-N-S",
            dataType: "string",
        },
        contract: buildProperty({
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/contracts`,
            name: "Contract",
            description: "Contract"
        }),
        thirdParty: buildProperty({
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/third_party`,
            name: "Third Party Config",
            description: "Third Party Config"
        }),
        infrastructure: buildProperty({
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/infrastructure`,
            name: "Infrastructure",
            description: "Infrastructure"
        }),
        customization: buildProperty({
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/customization`,
            name: "Customization",
            description: "Customization"
        }),
        features: buildProperty({
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/features`,
            name: "Features",
            description: "Features"
        }),
        modifiedAt: {
            name: "Modified At",
            dataType: "date",
            autoValue: "on_update",
            readOnly: true,
        },
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
    },
});
