import { buildCollection, buildProperty } from "firecms";
import { GenerateDeployActions } from "../collection_actions/DeployActions";
import { collectionVersionedPrefix } from "../data_version";

export const infrastructureCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/infrastructure`,
    name: "Infrastructure",
    singularName: "Infrastructures",
    group: "Main",
    description: "Tenant Infrastructures",
    textSearchEnabled: false,
    icon: "SettingsInputComponent",
    Actions: GenerateDeployActions,
    properties: {
        name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
        },
        firebase: buildProperty({
            name: "Firebase Configuration",
            dataType: "map",
            properties: {
                apiKey: {
                    name: "API Key",
                    description: "API Key",
                  validation: { required: true },
                  dataType: "string",
                },
                authDomain: {
                    name: "Auth Domain",
                    description: "Auth Domain",
                  validation: { required: true },
                  dataType: "string",
                },
                databaseURL: {
                    name: "Database Url",
                    description: "Database Url",
                  validation: { required: true },
                  dataType: "string",
                },
                projectId: {
                    name: "Project Id",
                    description: "Project Id",
                  validation: { required: true },
                  dataType: "string",
                },
                storageBucket: {
                    name: "Storage Bucket",
                    description: "Storage Bucket",
                  validation: { required: true },
                  dataType: "string",
                },
                messagingSenderId: {
                    name: "Messaging Sender Id",
                    description: "Messaging Sender Id",
                  validation: { required: true },
                  dataType: "string",
                },
                appId: {
                    name: "App Id (web)",
                    description: "App Id (web)",
                  validation: { required: true },
                  dataType: "string",
                },
                measurementId: {
                    name: "Measurement Id",
                    description: "Measurement Id",
                  dataType: "string",
                },
              },
            expanded: true,
        }),
        firebaseAndroid: buildProperty({
          name: "FB Android",
          dataType: "map",
          properties: {
              packageName: {
                name: "Package Name",
                description: "Package Name",
                dataType: "string",
              },
              appId: {
                name: "App Id (android)",
                description: "App Id (android)",
                validation: { required: true },
                dataType: "string",
              },
              googleServicesJsonFirebaseUrl: {
                name: "google-services.json",
                description: "google-services.json",
                dataType: "string",
                storage: {
                  storagePath: "infrastructure/android",
                  acceptedFiles: ["application/json", "application/javascript"],
                  maxSize: 1048576
                },
              },
              googleServicesJsonFirebaseSignedUrl: {
                name: "google-services.json (signed)",
                dataType: "string",
                readOnly: true
              },
            },
          expanded: true,
      }),
      firebaseIos: buildProperty({
        name: "FB iOS",
        dataType: "map",
        properties: {
            bundleId: {
              name: "Bundle ID",
              description: "Bundle ID",
              dataType: "string",
            },
            appId: {
              name: "App Id (ios)",
              description: "App Id (ios)",
              validation: { required: true },
              dataType: "string",
            },
            appStoreId: {
              name: "App Store ID",
              description: "App Store ID",
              dataType: "string",
            },
            googleServicesInfoPlistFirebaseUrl: {
              name: "GoogleServices-Info.plist",
              description: "GoogleServices-Info.plist",
              dataType: "string",
              storage: {
                storagePath: "infrastructure/ios",
              },
            },
            googleServicesInfoPlistFirebaseSignedUrl: {
              name: "GoogleServices-Info.plist (signed)",
              dataType: "string",
              readOnly: true
            },
            provisioningProfileFirebaseUrl: {
              name: "*.mobileprovision",
              description: "*.mobileprovision",
              dataType: "string",
              storage: {
                storagePath: "infrastructure/ios",
              },
            },
            provisioningProfileFirebaseSignedUrl: {
              name: "*.mobileprovision (signed)",
              dataType: "string",
              readOnly: true
            },
          },
        expanded: true,
      }),
      firebaseDeployment: buildProperty({
        name: "Firebase Deployment",
        dataType: "map",
        properties: {
            firebaseCiToken: {
              name: "Firebase CI Token",
              description: "Firebae CI Token",
              validation: { required: true },
              dataType: "string",
            },
            serviceAccountJsonFirebaseUrl: {
              name: "service account *.json",
              description: "service account *.json",
              dataType: "string",
              storage: {
                storagePath: "infrastructure/deployment",
                acceptedFiles: ["application/json", "application/javascript"],
                maxSize: 1048576
              },
            },
            serviceAccountJsonFirebaseSignedUrl: {
              name: "service account *.json (signed)",
              dataType: "string",
              readOnly: true
            },
          },
        expanded: true,
        }),
        settings: buildProperty({
            name: "Settings",
            dataType: "map",
            properties: {
                adminClientEmail: {
                    name: "Admin Client Email",
                    description: "Admin Client Email",
                  validation: { required: true },
                  dataType: "string",
                },
                adminPrivateKey: {
                    name: "Admin Private Key",
                    description: "Admin Private Key",
                  validation: { required: true },
                  dataType: "string",
                },
              },
            expanded: true,
        }),
        modifiedAt: {
          name: "Modified At",
          dataType: "date",
          autoValue: "on_update",
          readOnly: true,
      },
      createdAt: {
          name: "Created At",
          dataType: "date",
          autoValue: "on_create",
          readOnly: true,
      },
    },
});
