import { AltRoute, Grading, Label, Speed, Whatshot } from "@mui/icons-material";
import { Button, Chip, IconButton } from "@mui/material";
import {
    CollectionActionsProps,
    useSnackbarController
} from "firecms";
import React, { createElement, Fragment } from "react";

export function GenerateDeployActions({ selectionController }: CollectionActionsProps) {

    
    const snackbarController = useSnackbarController();

    // const onClickAndroid = (event: React.MouseEvent) => {
    //     const selectedEntities = selectionController?.selectedEntities;
    //     for (const entity of selectedEntities) {
    //         snackbarController.open({
    //             type: "info",
    //             message: `Deploying Android App for: ${event.button.toString()} ${entity.values.infra_name}`
    //         });
    //     }

    // };

    // const onClickIos = (event: React.MouseEvent) => {
    //     const selectedEntities = selectionController?.selectedEntities;
    //     for (const entity of selectedEntities) {
    //         snackbarController.open({
    //             type: "info",
    //             message: `Deploying iOS App for: ${event.button.toString()} ${entity.values.infra_name}`
    //         });
    //     }

    // };

    // const onClickTripManager = (event: React.MouseEvent) => {
    //     const selectedEntities = selectionController?.selectedEntities;
    //     for (const entity of selectedEntities) {
    //         snackbarController.open({
    //             type: "info",
    //             message: `Deploying Trip Manager for: ${event.button.toString()} ${entity.values.infra_name}`
    //         });
    //     }

    // };

    // const label = (
    //     <Chip icon={<Speed />} label="Test Deployment" variant="outlined" color="primary"/>
    // );

    // const buttonAndroid = (
    //     <Button onClick={onClickAndroid} color="primary">
    //         Android
    //     </Button>
    // );

    // const buttonIos = (
    //     <Button onClick={onClickIos} color="primary">
    //         iOS
    //     </Button>
    // );

    // const buttonTripManager = (
    //     <Button onClick={onClickTripManager} color="primary">
    //         Trip Manager
    //     </Button>
    // );

    // const b1Element = createElement("span", null, buttonAndroid);
    // const b2Element = createElement("span", null, buttonIos);
    // const b3Element = createElement("span", null, buttonTripManager);

    // const element = createElement(
    //     Fragment,
    //     null,
    //     label,
    //     b1Element,
    //     b2Element,
    //     b3Element
    // )

    const label = (
        <Chip label="Infrastructure documentation ->" variant="filled" color="primary"/>
    );
    const docsBtn = (
        <IconButton
            href={"https://docs.google.com/document/d/19GgRL8lgKWpnpTMpwQQ6Li2sRPY7uLDonXZRoVGkOto/edit"}
            rel="noopener noreferrer"
            target="_blank"
            component={"a"}
            size="large">
            <Grading/>
        </IconButton>
    );

    const docsBtnSpan = createElement("span", null, docsBtn);

    const element = createElement(
        Fragment,
        null,
        label,
        docsBtnSpan
    )

    return (
        element
    );

}
