import { buildCollection, buildProperty } from "firecms";
import CustomColorTextField from "../custom_field/CustomColorTextField";
import { collectionVersionedPrefix } from "../data_version";
import { locales } from "./enums";

export const customizationCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/customization`,
    name: "Customization",
    singularName: "Customization",
    group: "Main",
    description: "Customization Configurations",
    textSearchEnabled: false,
    icon: "ColorLens",
    properties: {
        name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
        },
        appName: {
          name: "App Name",
          description: "App Name",
          dataType: "string"
        },
        appIconFirebaseUrl: {
          name: "App Icon",
          description: "App Icon",
          dataType: "string",
          storage: {
            storagePath: "images/app_icons",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000"
            }
          },
        },
        appIconFirebaseSignedUrl: {
          name: "App Icon (signed)",
          dataType: "string",
          readOnly: true
        },
        configuration: buildProperty({
            name: "Color Configuration",
            dataType: "map",
            properties: {
                primaryColor: {
                  name: "Primary Color",
                  description: "Primary Color",
                  validation: { required: true },
                  Field: CustomColorTextField,
                  dataType: "string",
                  customProps: {
                    color: "#2a2116"
                }
                },
                secondaryColor: {
                  name: "Secondary Color",
                  description: "Secondary Color",
                  validation: { required: true },
                  Field: CustomColorTextField,
                  dataType: "string",
                  customProps: {
                    color: "#ff650d"
                }
                },
              },
            expanded: true,
        }),
        privacyPolicyFirebaseUrl: {
          name: "Privacy Policy (txt)",
          description: "Privacy Policy",
          dataType: "string",
          storage: {
            storagePath: "privacy_policies",
            acceptedFiles: ["text/plain"],
            metadata: {
                cacheControl: "max-age=1000000"
            }
          },
        },
        privacyPolicyFirebaseSignedUrl: {
          name: "Privacy Policy (txt) (signed)",
          dataType: "string",
        },
        termsOfServiceFirebaseUrl: {
          name: "Terms of Use (txt)",
          description: "Terms of Use",
          dataType: "string",
          storage: {
            storagePath: "terms_of_use",
            acceptedFiles: ["text/plain"],
            metadata: {
                cacheControl: "max-age=1000000"
            }
          },
        },
        termsOfServiceFirebaseSignedUrl: {
          name: "Terms of Use (txt) (signed)",
          dataType: "string",
        },
        locales: {
          name: "Languages",
          dataType: "array",
          of: {
              dataType: "string",
              enumValues: locales
          },
          validation: { required: true }
        },
        modifiedAt: {
          name: "Modified At",
          dataType: "date",
          autoValue: "on_update",
          readOnly: true,
        },
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
    },
});
