import React from "react";
import { createRoot } from "react-dom/client";

import TenantManagementAppV1 from "./TenantManagement/TenantManagementAppV1";

const container = document.getElementById("root");
const root = createRoot(container as any);
root.render(
    <React.StrictMode>
        <TenantManagementAppV1/>
    </React.StrictMode>
);
