import { buildCollection, buildProperty } from "firecms";
import { collectionVersionedPrefix } from "../data_version";

export const featuresCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/features`,
    name: "Features",
    singularName: "Features",
    group: "Main",
    description: "Features Configurations",
    textSearchEnabled: false,
    icon: "ChecklistRtl",
    properties: {
      name: {
        name: "Name",
        dataType: "string",
        validation: { required: true },
    },
        vouchers: buildProperty({
            name: "Vouchers",
            dataType: "map",
            properties: {
                operatorVouchers: {
                    name: "Operator Vouchers",
                    description: "Operator Vouchers",
                  dataType: "boolean",
                },
                tripVouchers: {
                    name: "Trip Vouchers",
                    description: "Trip Vouchers",
                    dataType: "boolean",
                },
              },
            expanded: true,
        }),
        content: buildProperty({
          name: "Content",
          dataType: "map",
          properties: {
              wordpressIntegration: {
                  name: "Wordpress",
                  description: "Wordpress",
                  dataType: "boolean",
              },
              weatherIntegration: {
                name: "Weather",
                description: "Weather",
                dataType: "boolean",
              },
              attractionMap: {
                name: "Attraction Map",
                description: "Attraction Map",
                dataType: "boolean",
              },
              webcams: {
                name: "Webcams",
                description: "Webcams",
                dataType: "boolean",
              },
            },
          expanded: true,
      }),
        aggregation: buildProperty({
          name: "Aggregation",
          dataType: "map",
          properties: {
              aggregateThis: {
                  name: "Include in aggregated data",
                  description: "Include in aggregated data",
                dataType: "boolean",
              },
            },
          expanded: true,
      }),
      modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    },
});
