// Replace with your Firebase config
export const firebaseConfig = {
    apiKey: "AIzaSyBTDV7MHWAaSrhEmYeij9pUDbnS1iHBXYc",
    authDomain: "nc-tenant-mgmt-dev.firebaseapp.com",
    databaseURL: "https://nc-tenant-mgmt-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nc-tenant-mgmt-dev",
    storageBucket: "nc-tenant-mgmt-dev.appspot.com",
    messagingSenderId: "636752794209",
    appId: "1:636752794209:web:cc2e4c3c1318a298701020",
    measurementId: "G-CH9XGBVDH3",
};
