import { getAnalytics, logEvent } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";
import {
    Authenticator,
    FirebaseCMSApp
} from "firecms";
import { Fragment, createElement, useCallback } from "react";

import { AltRoute, CloudDone, ScreenShare, Whatshot } from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";

import * as pckg from "../../package.json";
import { firebaseConfig } from "../firebase_config";
import { infrastructureCollection } from "./collections/infrastructure_collection";
import { operatorsCollection } from "./collections/operators_collection";
import logo from "./images/tenant-management-512.png";

import { textSearchController } from "./text_search";

import "@fontsource/ibm-plex-mono";
import "typeface-rubik";

import { TenantManagementLoginView } from "./TenantManagementLoginView";
import { contractsCollection } from "./collections/contract_collection";
import {
    customCollectionOverrideHandler
} from "./collections/custom_collection_resolver";
import { customizationCollection } from "./collections/customization_collection";
import { featuresCollection } from "./collections/features_collection";
import { thirdPartyCollection } from "./collections/third_party_collection";
import CustomColorTextField from "./custom_field/CustomColorTextField";
import { currentDataVersion } from "./data_version";

function TenantManagementAppV1() {

    const firebaseLink = (
        <Tooltip
            title="Firestore">
            <IconButton
                href={`https://console.firebase.google.com/project/${firebaseConfig.projectId}/firestore`}
                rel="noopener noreferrer"
                target="_blank"
                component={"a"}
                size="large">
                <Whatshot/>
            </IconButton>
        </Tooltip>
    );

    const ideInformationText = `IDE Version: ${pckg.version}`;
    const ideInformation = (
        <Tooltip
            title={ideInformationText}>
            <Chip icon={<ScreenShare />} label={ideInformationText} variant="outlined" color="primary"/>
        </Tooltip>
    );

    const environmentInformationText = `Environment: ${firebaseConfig.projectId}`;
    const environmentInformation = (
        <Tooltip
            title={environmentInformationText}>
            <Chip icon={<CloudDone />} label={environmentInformationText} variant="outlined" color="primary"/>
        </Tooltip>
    );

    const dataInformationText = `Data Version: ${currentDataVersion}`;
    const dataInformation = (
        <Tooltip
            title={dataInformationText}>
            <Chip icon={<AltRoute />} label={dataInformationText} variant="outlined" color="primary"/>
        </Tooltip>
    );

    const ideVersionElement = createElement("span", null, ideInformation);
    const dataVersionElement = createElement("span", null, dataInformation);
    const envElement = createElement("span", null, environmentInformation);
    // Using the React Fragments API:
    const element = createElement(
        Fragment,
        null,
        ideVersionElement,
        dataVersionElement,
        envElement,
        firebaseLink
    )

    const onFirebaseInit = (config: object) => {
        // Just calling analytics enables screen tracking
        getAnalytics();
    };

    const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({
                                                                                user,
                                                                                authController
                                                                            }) => {

        if (user?.email && !user?.email.endsWith("nomadcloud.app")) {
            throw Error("Error");
        }

        return true;
    }, []);

    const collections = [
        operatorsCollection,
        infrastructureCollection,
        thirdPartyCollection,
        contractsCollection,
        customizationCollection,
        featuresCollection
    ];

    const onAnalyticsEvent = useCallback((event: string, data?: object) => {
        const analytics = getAnalytics();
        logEvent(analytics, event, data);
    }, []);

    return <FirebaseCMSApp
        name={"Tenant Management"}
        authentication={myAuthenticator}
        signInOptions={[
            "google.com"
        ]}
        textSearchController={textSearchController}
        allowSkipLogin={false}
        logo={logo}
        collections={(params) => collections}
        collectionOverrideHandler={customCollectionOverrideHandler}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={onFirebaseInit}
        toolbarExtraWidget={element}
        LoginView={TenantManagementLoginView}
        onAnalyticsEvent={onAnalyticsEvent}
        primaryColor="#A18DC1"
        secondaryColor="#65BAA8"
        fields={{
            test_custom_field: {
                name: "Test custom field",
                dataType: "string",
                Field: CustomColorTextField
            }
        }}
    />;

}

export default TenantManagementAppV1;
