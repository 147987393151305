import { FirebaseLoginView, FirebaseLoginViewProps } from "firecms";

export function TenantManagementLoginView(props: FirebaseLoginViewProps) {

    return (
        
        <FirebaseLoginView
            {...props}
            disableSignupScreen={false}
            noUserComponent={<>
                No User!
            </>}
        />
    );
}